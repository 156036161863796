import "./Buildings.scss";

import Building, { BuildingProps } from "../../components/Building";
import pageDecoration from "../../static/assets/images/about/decoration.webp";
import pageDecorationDesktop from "../../static/assets/images/about/decoration-desktop.webp";
import buildingImage from "../../static/assets/images/buildings/building.webp";
import apartmentImage from "../../static/assets/images/buildings/apartment.webp";
import restaurantImage from "../../static/assets/images/buildings/restaurant.webp";

const buildings: BuildingProps[] = [
  {
    title: "Budynek",
    description: "Budynek został zbudowany w 1929 roku, przed wojną w mieścił się w nim skład wódek i zegarmistrz. W czasie wojny budynek został zamieniony na posterunek żandarmerii niemieckiej. Po wojnie przez krótki czas znajdował tu sie posterunek Milicji Obywatelskiej, a następnie przez wiele lat Urząd Gminy Białowieża.",
    imageUrl: buildingImage
  },
  {
    title: "Apartamenty",
    description: "Budynek powstał na przełomie XIX/XX wieku, został poddany renowacji z szacunkiem dla tradycji. Do dyspozycji oddajemy cztery pokoje, dwa dwuosobowe i dwa apartamenty. Pokoje charakteryzuje połączenie nowoczesności z tradycją, każdy z pokoi wyposażony został w klimatyzację, TV, sejf i WIFI, ale wszechobecne drewno, piece i gliniana podłoga przenoszą nas w zamierzchłe czasy, cieszą oko i gwarantują wspaniały wypoczynek.",
    imageUrl: apartmentImage
  },
  {
    title: "Restauracja",
    description: "Przekraczając próg restauracji w mgnieniu oka zorientujesz się, że właśnie rozpoczyna się dla Ciebie wspaniała podróż… przez kulinarne doznania… muzyczne nastroje… ucztę dla oczu… w niesamowitej atmosferze, jaką tworzymy specjalnie dla Ciebie.",
    imageUrl: restaurantImage
  }
];

const Buildings = () => {
  return (
    <section id="buildings">
      <img
        className="decoration-mobile"
        src={pageDecoration}
        alt="Dekoracja sekcji budynków - Stoczek 1929"
      />
      <img
        className="decoration-desktop"
        src={pageDecorationDesktop}
        alt="Dekoracja sekcji budynków - Stoczek 1929"
      />
      {buildings.map((building, i) => <Building key={`building ${i+1}`} {...building} />)}
    </section>
  );
};

export default Buildings;
