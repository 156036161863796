import "./Specials.scss";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

type MenuItem = {
  _id: string;
  name: string;
  price: number;
}

const menuItems: MenuItem[] = [
  {
    _id: "1",
    name: "Pielmieni z mięsem na sosie maślano-śmietanowym",
    price: 40
  },
  {
    _id: "2",
    name: "Kartacze z dziczyzną pod okrasą ze skwarek i smażonej cebuli z kapustą marynowaną",
    price: 50
  },
  {
    _id: "4",
    name: "Gołąbki wwołowe w kiszonej kapuście z ziemniakami",
    price: 43
  },
  {
    _id: "5",
    name: "Babka ziemniaczana ze skwarkami, kapustą marynowaną i kwaśną śmietaną",
    price: 35
  },
  {
    _id: "6",
    name: "Jagnięcina z warzywami i babką ziemniaczaną",
    price: 110
  },
];

const Specials = () => {
  return (
    <section id="menu" className="menu">
      <div className="menu__desktop">
        <div className="menu__card">
          <div className="menu__card--info">
            <p>
              W menu znajdziecie Państwo ślady wielokulturowości naszego
              regionu, króluje kuchnia białoruska, ukraińska, litewska,
              żydowska, polska i rosyjska. Menu zmieniamy zgodnie z porami roku.
              Korzystamy z dostawców bio żywności, zaopatrujemy się w
              gospodarstwach ekologicznych.
            </p>
            <h3>Zapraszamy do stołu!</h3>
          </div>
          <div className="menu__card--content">
            <h2 className="menu__header">Nasze specjały</h2>
            {!!menuItems.length && (
              <ul>
                {menuItems.map(({ _id, name, price }) => (
                    <li key={`${_id} menu category - desktop`}>
                      <h3>{name}</h3>
                      <span>{price},-</span>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Specials;
