import "./Banner.scss";

import leftFlower from "../../static/assets/images/about/left-flower.webp";
import rightFlower from "../../static/assets/images/about/right-flower.webp";

const Banner = () => {
  return (
    <div className="banner">
      <div className="box">
        <img src={leftFlower} alt="lewa dekoracja" id="left-flower" />
        <p>
        Kiedy pasja spotyka się z tradycją, historią <br/> i zamiłowaniem do piękna powstaje miejsce niezwykłe.
        </p>
        <img src={rightFlower} alt="prawa dekoracja" id="right-flower"/>
      </div>
    </div>
  );
};

export default Banner;
