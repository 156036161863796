import './Header.scss';
import Logo from '../Logo';
import NavBar from '../Nav';
import LanguageList from '../LanguageList/LanguageList';

type HeaderProps = {
  toggleModal: () => void;
}

const Header = ({toggleModal}: HeaderProps) => {
  return (
    <>
    <header>
      <Logo />
      <LanguageList />
      <NavBar toggleModal={toggleModal}/>
    </header>
    </>
  );
};

export default Header;
