import './LanguageList.scss';
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';
import i18n from '../../translations/i18n';
import { useEffect, useState } from 'react';

type LanguageListType = {
  isOpen?: boolean;
};

const LanguageList = ({ isOpen }: LanguageListType) => {
  const [currLang, setCurrLang] = useState('');
  const handlePolish = () => {
    i18n.changeLanguage('pl');
    setCurrLang('pl');
  };
  const handleEnglish = () => {
    i18n.changeLanguage('en');
    setCurrLang('en');
  };

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const languages = [
    {
      key: 'English language button',
      title: 'English',
      lang: 'en',
      onClick: handleEnglish,
    },
    {
      key: 'Polish language button',
      title: 'Polski',
      lang: 'pl',
      onClick: handlePolish,
    },
  ];

  useEffect(() => {
    setCurrLang(getLanguage());
  }, []);

  return (
    <div className={`${isOpen ? 'language-list list-open' : 'language-list'}`}>
      <MDBDropdown>
        <MDBDropdownToggle>{getLanguage()}</MDBDropdownToggle>
        <MDBDropdownMenu>
          {languages.map(({ key, title, lang, onClick }) => (
            <MDBDropdownItem
              key={key}
              onClick={onClick}
              className={`${currLang === lang && 'active'}`}
              link
            >
              {title}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export default LanguageList;
