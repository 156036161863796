import "./Rooms.scss";

import headerDec from "../../static/assets/images/rooms/rooms-header-dec.webp";
import logo from "../../static/assets/images/rooms/logo.webp";
import { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import { ROOMS_GROQ, ROOMS_HEADER_GROQ } from "../../utils/urls";
import { getLanguage } from "../../utils/currentLanguage";
import { Link } from "react-router-dom";

export enum RoomType {
  TwoPerson = "TWO_PERSON",
  Apartament = "APARTAMENT",
}

type RoomEqType = {
  _id: string;
  name: string;
  name_en: string;
}

export type Room = {
  _id: string;
  roomType: RoomType;
  title: string;
  title_en: string;
  description: string;
  description_en: string;
  price: string;
  price_en: string;
  equipment_header: string;
  equipment_header_en: string;
  roomEq: RoomEqType[];
  href: string;
  imageUrl: string;
  images: Array<any>;
};

const Rooms = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [quote, setQuote] = useState("");
  const [quoteEn, setQuoteEn] = useState("");
  const [currQuote, setCurrQuote] = useState("");
  const [desc, setDesc] = useState("");
  const [descEn, setDescEn] = useState("");
  const [currDesc, setCurrDesc] = useState("");
  const lang = getLanguage();

  const handleCurrContent = () => {
    const lang = getLanguage();
    switch (lang) {
      case "pl":
        setCurrQuote(quote);
        setCurrDesc(desc);
        break;
      case "en":
        setCurrQuote(quoteEn);
        setCurrDesc(descEn);
        break;
    }
  };

  const loadRoomHeader = async () => {
    try {
      const { quote, quote_en, description, description_en } =
        await sanityClient.fetch(ROOMS_HEADER_GROQ);
      setQuote(quote);
      setQuoteEn(quote_en);
      setDesc(description);
      setDescEn(description_en);

      switch (lang) {
        case "pl":
          setCurrQuote(quote);
          setCurrDesc(description);
          break;
        case "en":
          setCurrQuote(quote_en);
          setCurrDesc(description_en);
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadRooms = async () => {
    try {
      const response = await sanityClient.fetch(ROOMS_GROQ);
      setRooms(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadRooms();
    loadRoomHeader();
  }, []);

  useEffect(() => {
    handleCurrContent();
  }, [getLanguage()]);

  return (
    <section id="rooms" className="rooms">
      <div className="rooms__logo">
        <img src={logo} alt="Stoczek 1929 logo" />
      </div>
      <div className="rooms__header">
        <p className="rooms__header__intro">{currQuote}</p>
        <div className="rooms__header__desc">
          <div className="rooms__header__title">
            <h1>Noclegi</h1>
            <img
              src={headerDec}
              alt="Noclegi dekoracja nagłówka - Stoczek 1929"
            />
          </div>
          <p className="rooms__header__content">{currDesc}</p>
        </div>
      </div>
      <div className="box">
        <h3>pokoje dwuosobowe</h3>
        <ul>
          {rooms.map(({ _id, roomType, title, title_en, imageUrl }) => {
            if (roomType !== RoomType.TwoPerson) return null;

            return (
              <li key={_id}>
                <div
                  className="box-item"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <h4>{lang === "pl" ? title : title_en}</h4>
                </div>
                <Link to={`/noclegi/${_id}`}>zobacz więcej...</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="box">
        <h3>apartamenty</h3>
        <ul>
          {rooms.map(({ _id, roomType, title, title_en, imageUrl }) => {
            if (roomType !== RoomType.Apartament) return null;

            return (
              <li key={_id}>
                <div
                  className="box-item"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <h4>{lang === "pl" ? title : title_en}</h4>
                </div>
                <Link to={`/noclegi/${_id}`}>zobacz więcej...</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Rooms;
