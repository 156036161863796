import Banner from "../../components/Banner"
import Hero from "../../components/Hero"
import Specials from "../../components/Specials"
import Buildings from "../../components/Buildings"

export const Home = () => {
  return(
    <div className='home'>
    
      <h1 className='main-title'>Restauracja Białowieża | Restauracja i Hotel Stoczek 1929</h1>
      <Banner />
      <Buildings />
      <Specials />
      <Hero />
    </div>
  )
}