import './Modal.scss';

import closeBtn from '../../static/assets/images/close-btn.webp';

type ModalProps = {
  isOpen?: boolean;
  toggleModal?: () => void;
}

const Modal = ({isOpen = true, toggleModal}: ModalProps) => {
  return (
    <section className={`res-modal ${isOpen && 'open'}`}>
      <div className="box">
        <h2>Rezerwacja Pokoi</h2>
        <ul>
          <li>
            <a href="https://panel.hotres.pl/v4_step1?oid=831&lang=pl&">
              Apartamenty <br/> Stoczek
            </a>
          </li>
          <li>
            <a href="https://panel.hotres.pl/v4_step1?oid=835&lang=pl&">
              Pokoje <br/> Przy Stoczku
            </a>
          </li>
        </ul>
        <img id='close-btn' src={closeBtn} alt="Zamknij modal" onClick={toggleModal}/>
      </div>
    </section>
  )
}

export default Modal;