import { useEffect, useState } from "react";
import "./Gallery.scss";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import sanityClient from "../../sanityClient";
import { GALLERY_IMAGES_GROQ } from "../../utils/urls";
import { ImageGallery } from "react-image-grid-gallery";

type Image = {
  _id: string;
  name: string;
  imageUrl: string;
};

const Gallery = () => {
  const [images, setImages] = useState<Image[]>([]);

  const loadImages = async () => {
    try {
      const response = await sanityClient.fetch(GALLERY_IMAGES_GROQ);
      setImages(response);
    } catch (e) {
      console.log(e);
    }
  };

  const imagesArray = images.map(({ name, imageUrl }) => ({
    src: imageUrl,
    alt: name,
  }));

  useEffect(() => {
    loadImages();
  }, []);

  return (
    <section className="gallery" id="gallery">
      <h1>Galeria</h1>
      {images.length > 0 && (
        <ImageGallery
          imagesInfoArray={imagesArray}
          columnCount={"auto"}
          columnWidth={230}
          gapSize={24}
        />
      )}
    </section>
  );
};

export default Gallery;
