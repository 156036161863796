import sanityClient from '@sanity/client';
import { DATASET, PROJECT_ID } from './utils/database';

export default sanityClient({
  projectId: PROJECT_ID,
  dataset: DATASET,
  apiVersion: '2022-02-03',
  token:
    'sk8dcwnN9QSU583xaIaSc8SQ9l2n9YzPeeC4pyAaaZKhJnaly7vkJjdTYHhe5V4CiS2ba3WNk20S0mLeHOydT1xtAtRcjFjg2QdPYcJDKvANdUcB69i7b8GjuE6wEWXwke9vPwboyaXWIVaVrnh4F7Vl6quhTZhtAEfNdevENXoMsNPGvSnS',
  useCdn: false,
  ignoreBrowserTokenWarning: true,
});
