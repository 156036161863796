import { Link, useParams } from "react-router-dom";
import "./Room.scss";

import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import { ROOM_GROQ } from "../../utils/urls";
import { Room as RoomT, RoomType } from "../Rooms";
import { getLanguage } from "../../utils/currentLanguage";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Room = () => {
  const [room, setRoom] = useState<RoomT>();
  const { roomId = "" } = useParams();
  const lang = getLanguage();

  const loadRoom = async () => {
    try {
      const response = await sanityClient.fetch(ROOM_GROQ(roomId));
      setRoom(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getRoomType = (type: RoomType) => {
    switch (type) {
      case RoomType.TwoPerson:
        return "Pokoje Dwuosobowe";
      case RoomType.Apartament:
        return "Apartamenty";
    }
  };

  useEffect(() => {
    loadRoom();
  }, [roomId]);

  return room ? (
    <section className="room">
      <div className="room__header">
        <div className="room__header__box">
          <h2>{getRoomType(room.roomType)}</h2>
          <h1>{lang === "pl" ? room.title : room.title_en}</h1>
          {room.images?.length > 0 && (
            <MDBCarousel showControls showIndicators>
              {room.images.map(({ src, alt }, i) => (
                <MDBCarouselItem
                  className="w-100 d-block"
                  itemId={i + 1}
                  src={src}
                  alt={alt}
                  key={`room image ${i + 1}`}
                />
              ))}
            </MDBCarousel>
          )}
        </div>
      </div>
      <div className="room__content">
        <div className="room__content__box">
          <p className="description">{room.description}</p>
          <span className="price">{room.price}</span>
          <div className="equipment">
            <h3>{room.equipment_header}</h3>
            {room.roomEq?.length > 0 && (
              <ul>
                {room.roomEq.map(({ _id, name, name_en }) => (
                  <li key={_id}>{lang === "pl" ? name : name_en}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="room__content__footer">
            <a className="reservation" href={room.href} target="_blank">
              zarezerwuj teraz
            </a>
            <Link className="other" to="/#rooms">
              wybierz inny pokój
            </Link>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default Room;
