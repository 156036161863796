import './NotFound.scss';
import logo from '../../static/assets/images/header/logo.webp';

const NotFound = () => (
  <section className="not-found">
    <img className='not-found__logo' src={logo} alt='Logo Stoczek' />
    <h1 className='not-found__header'>
      404 <br />
      Page Not Found
    </h1>
    <a href="/" className="not-found__link">Go back</a>
  </section>
)

export default NotFound;