import './Nav.scss';

import { useState } from 'react';

import hamburger from '../../static/assets/images/header/menu.webp';
import logo from '../../static/assets/images/rooms/logo.webp';
import closeBtn from '../../static/assets/images/close-btn.webp';
import { NavLink } from 'react-router-dom';

const navItems = [
  {
    key: 'home-nav-key',
    name: 'Strona główna',
    href: '/',
  },
  {
    key: 'about-nav-key',
    name: 'O nas',
    href: '/o-nas',
  },
  {
    key: 'menu-nav-key',
    name: 'Menu',
    href: '/menu',
  },
  {
    key: 'spa-nav-key',
    name: 'SPA',
    href: '/spa',
  },
  {
    key: 'rooms-nav-key',
    name: 'Noclegi',
    href: '/noclegi',
  },
  {
    key: 'gallery-nav-key',
    name: 'Galeria',
    href: '/galeria',
  },
  {
    key: 'contact-nav-key',
    name: 'Kontakt',
    href: '/kontakt',
  },
];

type NavBarProps = {
  toggleModal: () => void;
}

const NavBar = ({toggleModal}: NavBarProps) => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  
  const toggleMenu = () => setIsMenuShown(prev => !prev);

  const toggleMobileMenu = () => {
    setIsMenuShown(prev => !prev);
    toggleModal();
  }

  return (
    <nav>
      <div className="hamburger">
        <img src={hamburger} alt="" onClick={toggleMenu}/>
      </div>
      <ul className='nav-desktop'>
        {navItems.map(({ key, name, href }) => (
          <li key={key}>
            <NavLink to={href}>{name}</NavLink>
          </li>
        ))}
        <li id='reservation-link'>
          <a href='#' onClick={toggleModal}>Rezerwacja Pokoi</a>
        </li>
      </ul>
      <ul className={`${isMenuShown ? 'nav-mobile menu-open' : 'nav-mobile menu-close'}`}>
        <li className='close-btn'>
          <img src={closeBtn} alt="" onClick={toggleMenu}/>
        </li>
        <li className='logo-nav'>
          <img src={logo} alt="" />
        </li>
        {navItems.map(({ key, name, href }) => (
          <li key={key}>
            <NavLink to={href} onClick={toggleMenu}>{name}</NavLink>
          </li>
        ))}
        <li id='reservation-link'>
          <a href='#' onClick={toggleMobileMenu}>Rezerwacja Pokoi</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
