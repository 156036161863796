import './Logo.scss';
import logo from '../../static/assets/images/header/logo.webp';

const Logo = () => {
  return (
    <div className='logo'>
      <a href='/'>
        <img src={logo} alt='Logo Stoczek' />
      </a>
    </div>
  );
};

export default Logo;
