export const ABOUT_HEADER_GROQ = `*[_type == "about" && !(_id in path('drafts.**'))][0] {
  description,
  description_en,
  content,
  content_en,
  "imageUrl": image.asset->url,
}`;

export const FOOTER_GROQ = `*[_type == "footer" && !(_id in path('drafts.**'))][0] {
  address,
  phone,
  href,
  address_res,
  phone_res,
  hours,
  facebook,
  email,
  maps,
}`;

export const BUILDING_GROQ = `*[_type == "building" && !(_id in path('drafts.**'))] {
  title,
  title_en,
  description,
  description_en,
  "imageUrl": image.asset->url,
}`;

export const NEWS_GROQ = `*[_type == "news" && !(_id in path('drafts.**'))] | order(order asc) {
  publishedAt,
  title,
  title_en,
  content,
  content_en,
  "author": author.name,
  "imageUrl": image.asset->url,
}`;

export const ROOMS_HEADER_GROQ = `*[_type == "roomHeader" && !(_id in path('drafts.**'))][0] {
  quote,
  quote_en,
  description,
  description_en
}`;

export const ROOMS_GROQ = `*[_type == "room" && !(_id in path('drafts.**'))] | order(order asc) {
  _id,
  roomType,
  title,
  title_en,
  "imageUrl": images[0].asset->url,
}`;

export const ROOM_GROQ = (id: string) => `*[_type == "room" && _id == "${id}" && !(_id in path('drafts.**'))][0] {
  roomType,
  href,
  title,
  title_en,
  description,
  description_en,
  price,
  price_en,
  equipment_header,
  equipment_header_en,
  roomEq[] -> {
    _id,
    name,
    name_en
  },
  images[]{
    "src": asset->url,
    alt
  },
}`;

export const MENU_CATEGORIES_GROQ = `*[_type == "menuCategory" && !(_id in path('drafts.**'))] | order(order asc) {
  _id,
  name,
  name_en,
}`;

export const MENU_ITEMS_GROQ = `*[_type == "menuItem" && !(_id in path('drafts.**'))] | order(order asc) {
  _id,
  name,
  name_en,
  "category": menuCategory->_id,
  price,
}`;

export const MENU_HEADER_GROQ = `*[_type == "menuHeader" && !(_id in path('drafts.**'))][0] {
  description,
  description_en,
}`;

export const GALLERY_IMAGES_GROQ = `*[_type == "galleryImage" && !(_id in path('drafts.**'))] | order(order asc){
  name,
  "imageUrl": image.asset->url,
}`;

export const CONTACT_GROQ = `*[_type == "footer" && !(_id in path('drafts.**'))][0]{
  address,
  phone,
  href,
  address_res,
  phone_res,
  hours,
  facebook,
  email,
  maps
}`;

export const HERO_GROQ = `*[_type == "hero" && !(_id in path('drafts.**'))][0]{
  facebook,
  tripadvisor,
  maps,
  phone,
  header,
  header_en,
  href
}`;

export const SPA_GROQ = `*[_type == "spa" && !(_id in path('drafts.**'))][0] {
  header,
  header_en,
  bottomText,
  bottomText_en,
  imagesSpa[]{
    "src": asset->url,
    alt
  },
}`;
