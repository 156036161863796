import "./Building.scss";

import leftFlower from "../../static/assets/images/about/left-building.webp";
import rightFlower from "../../static/assets/images/about/right-building.webp";

export type BuildingProps = {
  title: string;
  description: string;
  imageUrl: string;
};

const Building = ({
  title,
  description,
  imageUrl,
}: BuildingProps) => {
  return (
    <>
      <article className="building-mobile">
        <h2>{title}</h2>
        <img
          src={rightFlower}
          alt="Prawy róg rekoracja nagłówka"
          className="right-flower"
        />
        <img
          src={leftFlower}
          alt="Lewy róg rekoracja nagłówka"
          className="left-flower"
        />
        <figure>
          <img src={imageUrl} alt="test" />
          <figcaption>
            <p>{description}</p>
          </figcaption>
        </figure>
      </article>
      <figure className="building-desktop">
        <img src={imageUrl} alt="test" className="building-desktop__img" />
        <figcaption>
          <img
            src={rightFlower}
            alt="Prawy róg rekoracja nagłówka"
            className="right-flower"
          />
          <img
            src={leftFlower}
            alt="Lewy róg rekoracja nagłówka"
            className="left-flower"
          />
          <h2>{title}</h2>
          <p>{description}</p>
        </figcaption>
      </figure>
    </>
  );
};

export default Building;
