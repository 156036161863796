import './About.scss';

import restaurant from '../../static/assets/images/about/restaurant.webp';
import titleDecoration from '../../static/assets/images/about/title-decoration.webp';

import { getLanguage } from '../../utils/currentLanguage';
import sanityClient from '../../sanityClient';
import { ABOUT_HEADER_GROQ } from '../../utils/urls';
import { useEffect, useState } from 'react';
import Buildings from '../../components/Buildings';

const About = () => {
  const [content, setContent] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [currContent, setCurrContent] = useState('');

  const handleCurrContent = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'pl':
        setCurrContent(content);
        break;
      case 'en':
        setCurrContent(contentEn);
        break;
    }
  };
  
  const loadContent = async () => {
    const lang = getLanguage();
    try {
      const { content, content_en } = await sanityClient.fetch(ABOUT_HEADER_GROQ);
      setContent(content);
      setContentEn(content_en);
      switch (lang) {
        case 'pl':
          setCurrContent(content);
          break;
        case 'en':
          setCurrContent(content_en);
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    handleCurrContent();
  }, [getLanguage()]);

  return (
    <section id='about'>
      <h1 className='main-title'>O nas</h1>
      <figure className='about-figure'>
        <img
          src={restaurant}
          alt='Wejście od ulicy do restauracji Stoczek 1929 w Białowieży'
          className='banner-img'
        />
        <figcaption>
          <h2>O nas</h2>
          <img
            src={titleDecoration}
            alt='Ozdoba nagłówka sekcji O nas - Stoczek 1929'
          />
          <p>
            {currContent}
          </p>
        </figcaption>
      </figure>
      <Buildings />
    </section>
  );
};

export default About;
