import { useState } from "react";
import "./App.scss";
import Header from "./common/Header";
import Modal from "./components/Modal";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import NotFound from "./pages/NotFound";
import Footer from "./common/Footer";
import About from "./pages/About";
import Menu from "./pages/Menu";
import Spa from "./pages/Spa";
import Rooms from "./pages/Rooms";
import Room from "./pages/Room";
import Gallery from "./pages/Gallery";
import { Contact } from "./pages/Contact";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen((prev) => !prev);

  return (
    <div className="App">
      <Modal isOpen={isOpen} toggleModal={toggleModal} />
      <Header toggleModal={toggleModal} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/o-nas" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/spa" element={<Spa />} />
        <Route path="/noclegi" element={<Rooms />} />
        <Route path="/noclegi/:roomId" element={<Room />} />
        <Route path='/galeria' element={<Gallery />} />
        <Route path='/kontakt' element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer toggleModal={toggleModal} />
    </div>
  );
}

export default App;
