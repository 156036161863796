import { useEffect, useState } from "react";
import "./Spa.scss";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import sanityClient from "../../sanityClient";
import { SPA_GROQ } from "../../utils/urls";

type ImageType = {
  src: string;
  alt: string;
};

type SpaType = {
  header: string;
  header_en: string;
  bottomText: string;
  bottomText_en: string;
  imagesSpa: ImageType[];
};

const Spa = () => {
  const [props, setProps] = useState<SpaType>();

  const loadImages = async () => {
    try {
      const response = await sanityClient.fetch(SPA_GROQ);
      setProps(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadImages();
  }, []);

  return (
    props ? (
      <section id="spa" className="spa">
        <div className="spa__gallery">
          {props.imagesSpa?.length > 0 && (
            <MDBCarousel showControls showIndicators>
              {props.imagesSpa.map(({src, alt}, i) => (
                <MDBCarouselItem
                  className="w-100 d-block"
                  itemId={i+1}
                  src={src}
                  alt={alt}
                  key={`${i + 1} spa gallery item`}
                />
              ))}
            </MDBCarousel>
          )}
        </div>
        <div className="spa__info">
          <p>
            {props.header}
          </p>
          <span>{props.bottomText}</span>
        </div>
        <h1 className="main-title">SPA</h1>
      </section>
    ) : null
  );
};

export default Spa;
