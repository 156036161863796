import './Contact.scss';

import phone from "../../static/assets/images/contact/icons/phone.webp";
import leftFlower from "../../static/assets/images/about/left-building.webp";
import rightFlower from "../../static/assets/images/about/right-building.webp";

export const Contact = () => {
  return (
    <section className="contact">
      
      <img
          src={leftFlower}
          alt="Lewy róg rekoracja nagłówka"
          className="left-flower"
        />
      <h1 className="contact-header">Kontakt</h1>
      <img
          src={rightFlower}
          alt="Prawy róg rekoracja nagłówka"
          className="right-flower"
        />
      <div className="contact-address">
        <h2>Adres</h2>
        <p>ul.Stoczek 74 17-230 Białowieża</p>
        <h3>Email</h3>
        <a href="mailto:restauracja@stoczek1929.pl">restauracja@stoczek1929.pl</a>
      </div>
      <div className="contact-phone">
        <h3>Dane</h3>
        <ul>
          <li>
            <h4>
              <img src={phone} alt="Phone" />
              HOTEL
            </h4>
            <a href="tel:+48793702130">(+48) 725 587 767</a>
          </li>
          <li>
            <h4>
              <img src={phone} alt="Phone" />
              RESTAURACJA
            </h4>
            <a href="tel:+48857303209">(+48) 85 730 32 09</a>
          </li>
        </ul>
      </div>
      <div className="ratio ratio-16x9 contact-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2417.7143613809353!2d23.8567042775824!3d52.70125032279336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47205d600dc3fdab%3A0x269a964b73365a5a!2sRestauracja%20Stoczek%201929!5e0!3m2!1spl!2spl!4v1721346132198!5m2!1spl!2spl"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};
