import "./Hero.scss";

import fbIcon from "../../static/assets/images/banner/fb.svg";
import tripIcon from "../../static/assets/images/banner/trip.svg";
import localizeIcon from "../../static/assets/images/banner/localize.svg";
import phoneIcon from "../../static/assets/images/banner/phone.svg";

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <ul className="hero__socials">
        <li>
          <a href="https://www.facebook.com/Stoczek1929">
            <img
              src={fbIcon}
              alt="hero facebook"
              className="hero__socials__icon"
            />
          </a>
        </li>
        <li>
          <a href="https://www.tripadvisor.com/Restaurant_Review-g274740-d3308347-Reviews-Restauracja_Stoczek_1929-Bialowieza_Podlaskie_Province_Eastern_Poland.html">
            <img
              src={tripIcon}
              alt="hero tripadvisor"
              className="hero__socials__icon"
            />
          </a>
        </li>
        <li>
          <a href="https://www.google.com/maps?q=dzikamalwa">
            <img
              src={localizeIcon}
              alt="hero localization"
              className="hero__socials__icon"
            />
          </a>
        </li>
        <li>
          <a href="tel:601588828">
            <img
              src={phoneIcon}
              alt="hero phone"
              className="hero__socials__icon"
            />
          </a>
        </li>
      </ul>
      <h2 className="hero__header">Odwiedź również naszą drugą restauracje</h2>
      <a
        className="hero__btn"
        href="https://www.dzikamalwa.pl/"
        target="_blank"
      >
        Zobacz więcej
      </a>
    </section>
  );
};

export default Hero;
