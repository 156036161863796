import "./Menu.scss";

import { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import sanityClient from "../../sanityClient";
import {
  MENU_CATEGORIES_GROQ,
  MENU_HEADER_GROQ,
  MENU_ITEMS_GROQ,
} from "../../utils/urls";
import { getLanguage } from "../../utils/currentLanguage";

type Category = {
  _id: string;
  name: string;
};

const Menu = () => {
  const [header, setHeader] = useState({
    description: '',
    description_en: '',
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [menuItems, setMenuItems] = useState([]);
  const [currCategory, setCurrCategory] = useState<Category>({
    _id: "",
    name: "",
  });
  const lang = getLanguage();

  const toggleCurrCategory = (id: string) =>
    setCurrCategory(categories.filter(({ _id }) => _id === id)[0]);

  const loadHeader = async () => {
    try {
      const response = await sanityClient.fetch(MENU_HEADER_GROQ);
      setHeader(response);
    } catch (e) {
      console.log(e);
    }
  };

  const loadCategories = async () => {
    try {
      const response = await sanityClient.fetch(MENU_CATEGORIES_GROQ);
      setCategories(response);
      setCurrCategory(response[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const loadMenu = async () => {
    try {
      const response = await sanityClient.fetch(MENU_ITEMS_GROQ);
      setMenuItems(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadHeader();
    loadCategories();
    loadMenu();
  }, []);

  return (
    <section id="menu" className="menu">
      <div className="menu__mobile">
        <div className="menu__card">
          <h1 className="menu__header">Menu</h1>
          <MDBAccordion borderless>
            {categories.map(({ _id, name }, index) => (
              <MDBAccordionItem collapseId={index} headerTitle={name} key={`category ${index+1}`}>
                <ul className="menu__info">
                  {menuItems.map(({ name, price, category }, index) => {
                    if (category !== _id) return;

                    return (
                      <li key={`${index} menu category - mobile`}>
                        <h4>{name}</h4>
                        <span>{price},-</span>
                      </li>
                    );
                  })}
                </ul>
              </MDBAccordionItem>
            ))}
          </MDBAccordion>
        </div>
        <div className="menu__desc">
          <h3>Zapraszamy do stołu!</h3>
        </div>
      </div>
      <div className="menu__desktop">
        <div className="menu__card">
          <div className="menu__card--info">
            {!!categories.length && (
              <ul>
                {categories.map(({ _id, name }) => (
                  <li
                    className={`${currCategory._id === _id && "active"}`}
                    key={_id}
                    onClick={() => toggleCurrCategory(_id)}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            )}
            <p>
              {lang === 'pl' ? header.description : header.description_en}
            </p>
            <h3>Zapraszamy do stołu!</h3>
          </div>
          <div className="menu__card--content">
            
          <h1 className="menu__header">Menu</h1>
            <h3>{currCategory.name}</h3>
            {!!menuItems.length && (
              <ul>
                {menuItems.map(({ name, price, category }, index) => {
                  if (category !== currCategory._id) return;

                  return (
                    <li key={`${index} menu category - desktop`}>
                      <h4>{name}</h4>
                      <span>{price},-</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
