import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translations_pl from "./pl/translations";
import translations_en from "./en/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ["en", "pl"],
    lgn: "pl",
    resources: {
      en: {
        translation: translations_en
      },
      pl: {
        translation: translations_pl
      }
    }
  });

export default i18n;

i18n.changeLanguage("pl");