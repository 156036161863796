import "./Footer.scss";
import logo from "../../static/assets/images/logo.webp";
import localize from "../../static/assets/images/contact/icons/localize.webp";
import phone from "../../static/assets/images/contact/icons/phone.webp";
import facebook from "../../static/assets/images/banner/fb.svg";
import trip from "../../static/assets/images/banner/trip.svg";
import maps from "../../static/assets/images/banner/localize.svg";
import footerDecoration from "../../static/assets/images/contact/footer.webp";
import { Link } from "react-router-dom";

type FooterProps = {
  toggleModal: () => void;
};

const Footer = ({ toggleModal }: FooterProps) => {
  return (
    <section id="contact">
      
      <div className="contact-blur-box">
        <div className="contact-box">
          <img
            className="contact-logo"
            src={logo}
            alt="Logo restauracji Stoczek 1929 w białowieży"
          />
          <ul className="socials">
            <li>
              <a
                href="https://www.facebook.com/stoczek1929"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tripadvisor.pl/Restaurant_Review-g188460-d1151908-Reviews-Stoczek-Bialowieza_Lublin_Lubelskie_Dolny_Lancusk_Village.html"
                target="_blank"
                rel="noreferrer"
              >
                <img src={trip} alt="TripAdvisor" />
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/maps/place/Stoczek/@51.2389949,22.565258,17z/data="
                target="_blank"
                rel="noreferrer"
              >
                <img src={maps} alt="Google Maps" />
              </a>
            </li>
          </ul>
          <div className="address">
            <h3>
              <img src={localize} alt="lokalizacja" />
              Adres
            </h3>
            <p>ul.Stoczek 74 17-230 Białowieża</p>
          </div>
          <div className="phone">
            <ul>
              <li>
                <h4>
                  <img src={phone} alt="Phone" />
                  HOTEL
                </h4>
                <a href="tel:+48793702130">(+48) 725 587 767</a>
              </li>
              <li>
                <h4>
                  <img src={phone} alt="Phone" />
                  RESTAURACJA
                </h4>
                <a href="tel:+48857303209">(+48) 85 730 32 09</a>
              </li>
              <li>
              <a href="mailto:restauracja@stoczek1929.pl">restauracja@stoczek1929.pl</a>
              </li>
            </ul>
          </div>
          <div className="hours">
            <h3>Godziny otwarcia restauracji</h3>
            <p>pon-pt 12:00-21:00</p>
            <p>sob-nd 12:00-22:00</p>
          </div>
          <ul className="map">
            <li>
              <h3>Mapa strony</h3>
            </li>
            <li>
              <Link to={"/"}>Strona główna</Link>
            </li>
            <li>
              <Link to={"/o-nas"}>O nas</Link>
            </li>
            <li>
              <Link to={"/menu"}>Menu</Link>
            </li>
            <li>
              <Link to={"/spa"}>SPA</Link>
            </li>
            <li>
              <Link to={"/noclegi"}>Noclegi</Link>
            </li>
            <li>
              <Link to={"/galeria"}>Galeria</Link>
            </li>
            <li>
              <Link to={"/kontakt"}>Kontakt</Link>
            </li>
          </ul>
        </div>
          <img src={footerDecoration} className="decoration" alt="dekoracja stopki" />
      </div>
    </section>
  );
};

export default Footer;
